/**
 * @prettier
 */

import React, { FunctionComponent } from "react";
import { Router, RouteComponentProps, Redirect } from "@reach/router";
import App from "App";
import AuthPage from "containers/AuthPage";
import { UserProvider } from "UserContext";
import { UnsavedProvider } from "LinkContext";
import Dashboard from "containers/Dashboard";
import BeMeasuredPage from "containers/BeMeasured";
// import BePlannedPage from "containers/BePlanned";
import NotFound from "components/NotFound";
import BePlannedMonthly from "containers/BePlannedMonthly";
import Privacy from "containers/Privacy";
import ErrorBoundary from "components/ErrorBoundary";
import PrivateRoute from "components/hoc/PrivateRoute";
import PublicRoute from "components/hoc/PublicRoute";
import Expired from "components/Public/Expired";
import Invalid from "components/Public/Invalid";
import OrganicCampaigns from "containers/BeAmplified/Campaigns/Organic";
import CampaignPage from "containers/BeAmplified/Campaigns/Organic/ViewCampaign/AutoFollowerCampaignPage";
import { CreateCampaign } from "components/BeAmplified/Campaigns/Organic/Create/AutoFollow/SharedCreateCampaign";
import { CreateAutoDm } from "containers/BeAmplified/Campaigns/Organic/CreateCampaign/AutoDm";
import AutoPosterCampaignPage from "containers/BeAmplified/Campaigns/Organic/ViewCampaign/AutoPosterCampaignPage";
import { CreateAutoPoster } from "components/BeAmplified/Campaigns/Organic/Create/AutoPoster/Twitter";
import AutoDmCampaignPage from "containers/BeAmplified/Campaigns/Organic/ViewCampaign/AutoDmCampaignPage";
import AutoLikerCampaignPage from "containers/BeAmplified/Campaigns/Organic/ViewCampaign/AutoLikerCampaignPage";
import GroupExtractorPage from "containers/BeAmplified/Campaigns/Organic/ViewCampaign/GroupExtractorPage";
import { GroupExtractor } from "components/BeAmplified/Campaigns/Organic/Create/GroupExtractor/Facebook";
import { CreateAutoLiker } from "containers/BeAmplified/Campaigns/Organic/CreateCampaign/AutoLiker";
import { CreateOrganicCampaign } from "containers/BeAmplified/Campaigns/Organic/CreateCampaign/AutoFollow";
import AutoCommenterCampaignPage from "containers/BeAmplified/Campaigns/Organic/ViewCampaign/AutoCommenterCampaignPage";
import { CreateAutoCommenter } from "containers/BeAmplified/Campaigns/Organic/CreateCampaign/AutoCommenter";
import { ContentPlannerFeed } from "containers/BeplannedV2/ContentPlannerData/ContentPlannerFeed";
import { PrimaryUserProvider } from "PrimaryUserContext";
import { AuthUserProvider } from "AuthUserContext";

export const AppRouter: FunctionComponent<RouteComponentProps> = () => (
  <ErrorBoundary>
    <AuthUserProvider>
      <PrimaryUserProvider>
        <UserProvider>
          <UnsavedProvider>
            <Router>
              <App path="/" location={{ pathname: "/" }}>
                <AuthPage path="sign-in" />
                <AuthPage path="forgot-password" />
                <AuthPage path="sign-up" />
                <Privacy path="privacy-policy" />
                <NotFound default />
                <Dashboard path="/">
                  {!window.location.pathname.includes("/public") ? (
                    <PrivateRoute path="/">
                      <BeMeasuredPage path="be-measured" />
                      <Redirect
                        from="be-planned-feed"
                        to="/content-plan-feed"
                        noThrow
                      />
                      {/* <BePlannedPage path="content-plan-feed" /> */}
                      <ContentPlannerFeed path="content-plan-feed" />
                      <Redirect
                        from="be-planned-monthly"
                        to="/monthly-conversation-planner"
                        noThrow
                      />
                      <BePlannedMonthly path="monthly-conversation-planner" />
                      {/* this needs refactoring */}
                      <CreateOrganicCampaign path="campaigns/organic/create" />
                      <CreateCampaign path="campaigns/organic/:uid/edit" />
                      <CreateCampaign path="campaigns/organic/:platform/:uid/edit" />
                      <CreateAutoDm path="campaigns/organic/create/auto-dm" />
                      <CreateAutoDm path="campaigns/organic/create/:platform/auto-dm" />
                      <CreateAutoDm path="campaigns/organic/create/auto-dm/:uid/edit" />
                      <CreateAutoDm path="campaigns/organic/create/:platform/auto-dm/:uid/edit" />
                      <CreateAutoPoster path="campaigns/organic/create/auto-poster" />
                      <CreateAutoLiker path="campaigns/organic/create/:platform/auto-liker" />
                      <CreateAutoCommenter path="campaigns/organic/create/:platform/auto-commenter" />

                      {/* Hide For now */}
                      {/* <BePlannedGrid path="be-planned-grid" /> */}
                      <OrganicCampaigns path="campaigns/organic" />
                      <CampaignPage path="campaigns/organic/:uid" />
                      <CampaignPage path="campaigns/organic/:platform/:uid" />
                      <AutoPosterCampaignPage path="campaigns/organic/autoposter/:uid" />
                      <AutoDmCampaignPage path="campaigns/organic/auto-dm/:uid" />
                      <AutoDmCampaignPage path="campaigns/organic/:platform/auto-dm/:uid" />
                      <AutoLikerCampaignPage path="campaigns/organic/auto-liker/:platform/:uid" />
                      <AutoCommenterCampaignPage path="campaigns/organic/auto-commenter/:platform/:uid" />
                      <GroupExtractor path="campaigns/organic/create/extractor" />
                      <GroupExtractorPage path="campaigns/organic/group-extractor/:platform/:uid" />
                    </PrivateRoute>
                  ) : (
                    <PublicRoute path="/public">
                      <BeMeasuredPage path="be-measured/:userCipher" />
                      {/* <BePlannedPage path="content-plan-feed/:userCipher" /> */}
                      <ContentPlannerFeed path="content-plan-feed/:userCipher" />
                      <BePlannedMonthly path="monthly-conversation-planner/:userCipher" />
                      <Expired path="expired" />
                      <Invalid path="invalid" />
                    </PublicRoute>
                  )}
                  <NotFound default />
                </Dashboard>
              </App>
            </Router>
          </UnsavedProvider>
        </UserProvider>
      </PrimaryUserProvider>
    </AuthUserProvider>
  </ErrorBoundary>
);
