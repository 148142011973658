/**
 * @prettier
 */
import React, { FunctionComponent, useState, useEffect } from "react";
import moment from "moment";
import { DateRange } from "materialui-daterange-picker";
import history from "history/browser";

import { PageType } from "types/PageType";
import BeMeasured from "components/BeMeasured";
import { useStyles } from "./styles";
import DateRangePicker from "components/Common/DateRange";
import { DB } from "firebase-tools/db";

import {
  appleMusicMedia,
  fbMedia,
  igMedia,
  twitterMedia,
  youtubeMedia,
  tiktokMedia,
} from "../../utils/fetchMetrics";
import { urlDates } from "utils/dateFormat";

import ClientAccounts from "components/ClientAccounts/ClientAccounts";
import { useUser } from "UserContext";
import { BeMeasuredContainer } from "components/styled/Analytics";
import { getPreviousMetrics } from "./helpers/getPreviousMetrics";
import { getMetrics } from "./helpers/getMetrics";
// import { usePrimaryUser } from "PrimaryUserContext";
import UserProfile from "./UserProfile";

export const BeMeasuredPage: FunctionComponent<PageType> = ({ location }) => {
  const classes = useStyles({});

  const [user]: any = useUser();
  // const [primaryUser]: any = usePrimaryUser();
  const authUser = Array.isArray(user) ? user[0][0] : user;
  // const selectedUser: any = primaryUser?.email ? primaryUser : authUser;
  const selectedUser: any = authUser;
  const [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: moment().subtract(7, "days").startOf("day").toDate(),
    endDate: moment().subtract(1, "days").startOf("day").toDate(),
  });

  // metrics
  const [metrics, setMetrics]: any = useState({
    fbMetrics: [],
    igMetrics: [],
    twitterMetrics: [],
    tiktokMetrics: [],
    youtubeMetrics: [],
    appleMusicMetrics: [],
    spotifyMetrics: [],
    igStoriesMetrics: [],
  });

  // prev metrics
  const [prevMetrics, setPrevMetrics]: any = useState({
    fbMetrics: [],
    igMetrics: [],
    twitterMetrics: [],
    tiktokMetrics: [],
    youtubeMetrics: [],
    appleMusicMetrics: [],
    spotifyMetrics: [],
    igStoriesMetrics: [],
  });

  // loading states
  const [loading, setLoading]: any = useState({
    fbLoading: false,
    fbMetricsLoading: false,
    igLoading: false,
    igMetricsLoading: false,
    twitterLoading: false,
    twitterMetricsLoading: false,
    tiktokLoading: false,
    tiktokMetricsLoading: false,
    youtubeLoading: false,
    youtubeMetricsLoading: false,
    spotifyLoading: false,
    spotifyMetricsLoading: false,
    appleMusicLoading: false,
    pageLoading: false,
    prevLoading: true,
  });

  // post states
  const [posts, setPosts]: any = useState({
    fbPosts: [],
    igPosts: [],
    twitterPosts: [],
    appleMusicPosts: [],
    tiktokPosts: [],
    youtubePosts: [],
    spotifyPosts: [],
  });

  const [igType, setIg] = useState("timeline");

  const [fbMetadata, setFbMeta] = useState({});
  const [igMetadata, setIgMeta] = useState({});
  const [isOpen, setIsOpen] = useState(true);
  const [previousDateRange, setPreviousDateRange]: any = useState({});
  const [previousClient, setPreviousClient]: any = useState("");
  const [paramsChange, setParamsChange]: any = useState("");

  const handSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setIg(event.target.value);
  };

  const from = new Date(dateRange.startDate as Date);
  let to = new Date(dateRange.endDate as Date);
  to = moment(to).add(1, "days").toDate();

  // find the days between from and to
  const differenceInDays = moment(to).diff(from, "days");
  // const olderFrom = moment(from).su
  const formatDate = moment(from).format("YYYY-MM-DD");
  const newStartDate = moment(formatDate)
    .subtract(differenceInDays, "days")
    .toDate();

  const getFbMedia = async () => {
    setLoading((loading) => ({ ...loading, fbLoading: true }));
    try {
      if (selectedUser?.facebook?.username?.length) {
        let incomingPosts = await fbMedia(
          selectedUser.facebook.username,
          from,
          to
        );
        incomingPosts = incomingPosts[selectedUser.facebook.username];
        setPosts((posts) => ({ ...posts, fbPosts: incomingPosts }));
      } else {
        setPosts((posts) => ({ ...posts, fbPosts: [] }));
        setLoading((loading) => ({ ...loading, fbLoading: false }));
      }
    } catch (e) {
      console.log(e);
      setPosts((posts) => ({ ...posts, fbPosts: [] }));
      setLoading((loading) => ({ ...loading, fbLoading: false }));
    }
    setLoading((loading) => ({ ...loading, fbLoading: false }));
  };

  const getIgMedia = async () => {
    setLoading((loading) => ({ ...loading, igLoading: true }));
    try {
      if (selectedUser?.facebook?.username) {
        let incomingPosts = await igMedia(
          selectedUser.facebook.username,
          from,
          to
        );
        incomingPosts = incomingPosts[selectedUser.facebook.username];
        setPosts((posts) => ({ ...posts, igPosts: incomingPosts }));
      } else {
        setLoading((loading) => ({ ...loading, igLoading: false }));
        setPosts((posts) => ({ ...posts, igPosts: [] }));
      }
    } catch (e) {
      console.log(e);
      setLoading((loading) => ({ ...loading, igLoading: false }));
      setPosts((posts) => ({ ...posts, igPosts: [] }));
      return;
    }
    setLoading((loading) => ({ ...loading, igLoading: false }));
  };

  const getTwitterMedia = async () => {
    setLoading((loading) => ({ ...loading, tiktokLoading: true }));
    try {
      if (selectedUser?.twitter?.username?.length) {
        const incomingPosts = await twitterMedia(
          selectedUser.twitter.username,
          from,
          to
        );
        setPosts((posts) => ({
          ...posts,
          twitterPosts: incomingPosts[selectedUser.twitter.username],
        }));
      } else {
        setPosts((posts) => ({
          ...posts,
          twitterPosts: [],
        }));
        setLoading((loading) => ({ ...loading, tiktokLoading: false }));
      }
    } catch (e) {
      console.log(e);
      setPosts((posts) => ({
        ...posts,
        twitterPosts: [],
      }));
      setLoading((loading) => ({ ...loading, tiktokLoading: false }));
      return;
    }
    setLoading((loading) => ({ ...loading, tiktokLoading: false }));
  };

  const getTikTokMedia = async () => {
    setLoading((loading) => ({ ...loading, tiktokLoading: true }));
    try {
      if (selectedUser?.tiktok?.username?.length) {
        const initialPosts = await tiktokMedia(
          selectedUser.tiktok.username,
          selectedUser.id,
          from,
          to
        );
        setPosts((posts) => ({
          ...posts,
          tiktokPosts: initialPosts[selectedUser.id],
        }));
      } else {
        setLoading((loading) => ({ ...loading, tiktokLoading: false }));
        setPosts((posts) => ({
          ...posts,
          tiktokPosts: [],
        }));
      }
    } catch (e) {
      console.log("TikTok Media Error", e);
      if (!e?.message?.includes("user aborted a request")) {
        setLoading((loading) => ({ ...loading, tiktokLoading: false }));
      }
      setPosts((posts) => ({
        ...posts,
        tiktokPosts: [],
      }));
      return;
    }
    setLoading((loading) => ({ ...loading, tiktokLoading: false }));
  };

  const getYouTubeMedia = async () => {
    setLoading((loading) => ({ ...loading, youtubeLoading: true }));
    try {
      if (selectedUser?.youtube?.username?.length) {
        const initialPosts = await youtubeMedia(
          user?.youtube?.username,
          from,
          to
        );

        setPosts((posts) => ({
          ...posts,
          youTubePosts: initialPosts[selectedUser?.youtube?.username],
        }));
      } else {
        setPosts((posts) => ({
          ...posts,
          youTubePosts: [],
        }));
        setLoading((loading) => ({ ...loading, youtubeLoading: false }));
      }
    } catch (e) {
      console.log(e);
      setPosts((posts) => ({
        ...posts,
        youTubePosts: [],
      }));
      setLoading((loading) => ({ ...loading, youtubeLoading: false }));
      return;
    }
    setLoading((loading) => ({ ...loading, youtubeLoading: false }));
  };

  const getAppleMusicMedia = async () => {
    setLoading((loading) => ({ ...loading, appleMusicLoading: true }));
    try {
      if (selectedUser?.appleMusic?.username?.length) {
        const initialPosts = await appleMusicMedia(
          selectedUser?.appleMusic?.username,
          from,
          to
        );

        setPosts((posts) => ({
          ...posts,
          appleMusicPosts: initialPosts[selectedUser?.appleMusic?.username],
        }));
      } else {
        setPosts((posts) => ({
          ...posts,
          appleMusicPosts: [],
        }));
        setLoading((loading) => ({ ...loading, appleMusicLoading: false }));
      }
    } catch (e) {
      console.log(e);
      setPosts((posts) => ({
        ...posts,
        appleMusicPosts: [],
      }));
      setLoading((loading) => ({ ...loading, appleMusicLoading: false }));
      return;
    }
    setLoading((loading) => ({ ...loading, appleMusicLoading: false }));
  };

  const getSpotifySongs = async () => {
    setLoading((loading) => ({ ...loading, spotifyLoading: true }));
    try {
      const db = new DB();

      if (selectedUser?.spotify?.username?.length) {
        const spotifyTracks: any = await db.metricsByDate(
          "spotify-top-tracks",
          selectedUser.spotify.username,
          from,
          to
        );
        setPosts((posts) => ({
          ...posts,
          spotifyPosts: spotifyTracks[spotifyTracks.length - 1]?.tracks || [],
        }));
      } else {
        setPosts((posts) => ({
          ...posts,
          spotifyPosts: [],
        }));
        setLoading((loading) => ({ ...loading, spotifyLoading: false }));
      }
    } catch (e) {
      console.log(e);
      setPosts((posts) => ({
        ...posts,
        spotifyPosts: [],
      }));
      setLoading((loading) => ({ ...loading, spotifyLoading: false }));
      return;
    }
    setLoading((loading) => ({ ...loading, spotifyLoading: false }));
  };

  const prevFrom = moment(from).subtract(7, "days").toDate();
  const prevTo = moment(to).subtract(7, "days").toDate();

  useEffect(() => {
    urlDates(dateRange, history, paramsChange, setDateRange, location);

    console.log({ selectedUser });

    getMetrics(
      selectedUser,
      newStartDate,
      to,
      dateRange,
      previousDateRange,
      previousClient,
      setMetrics,
      setFbMeta,
      setIgMeta,
      setLoading,
      setPreviousDateRange,
      setPreviousClient
    );
    getPreviousMetrics(
      selectedUser,
      prevFrom,
      prevTo,
      dateRange,
      previousDateRange,
      previousClient,
      setLoading,
      setPrevMetrics
    );
    getFbMedia();
    getIgMedia();
    getTwitterMedia();
    getTikTokMedia();
    getYouTubeMedia();
    getAppleMusicMedia();
    getSpotifySongs();
    // eslint-disable-next-line
  }, [dateRange, selectedUser, paramsChange, location]);

  const AccChosen = localStorage.getItem("account-chosen");
  let clients: any = localStorage.getItem("clients");
  clients = JSON.parse(clients);

  if (clients !== null && clients.length > 1 && isOpen && AccChosen !== null) {
    return <ClientAccounts toggleModal={setIsOpen} />;
  }

  localStorage.removeItem("account-chosen");

  return (
    <div className={classes.root} id="root-analytics">
      <BeMeasuredContainer id="analytics">
        <div className={classes.pageTitle} id="page-title">
          <span className={classes.newPageTitle}>Be-Measured</span>
          <div className={classes.datePrint}>
            <DateRangePicker
              dateRange={dateRange}
              setDateRange={setDateRange}
              setParamsChange={setParamsChange}
            />
          </div>
        </div>
        {selectedUser && <UserProfile user={selectedUser} />}
        <BeMeasured
          metrics={{
            ...metrics,
            igMetrics:
              igType === "timeline"
                ? metrics.igMetrics
                : metrics.igStoriesMetrics,
          }}
          igType={igType}
          dateRange={dateRange as any}
          newIgMetrics={metrics.igMetrics}
          newIgStoriesMetrics={metrics.igStoriesMetrics}
          igHandler={handSelectChange}
          user={selectedUser}
          loading={{ ...loading }}
          posts={{ ...posts }}
          fbMetadata={fbMetadata}
          igMetadata={igMetadata}
          prevMetrics={{
            facebook: prevMetrics.fbMetrics,
            twitter: prevMetrics.twitterMetrics,
            tiktok: prevMetrics.tiktokMetrics,
            youtube: prevMetrics.youtubeMetrics,
            appleMusic: prevMetrics.appleMusicMetrics,
            spotify: prevMetrics.spotifyMetrics,
            instagram:
              igType === "timeline"
                ? prevMetrics.igMetrics
                : prevMetrics.igStoriesMetrics,
          }}
        />
      </BeMeasuredContainer>
    </div>
  );
};
