/**
 * @prettier
 */

import React, { FunctionComponent, ChangeEvent } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ReactComponent as EMAIL } from "statics/media/email.svg";
import { makeStyles } from "@material-ui/core/styles";

type emailType = {
  value?: string;
  autoFocus?: boolean;
  helperText?: any;
  error?: any;
  color?: string;
  disabled?: boolean;
  backgroundColor?: string;
  marginTop?: string;
  width?: string;
  onChangeHandler?: (e: ChangeEvent<HTMLInputElement>) => any;
};

const useStyles = makeStyles((theme) => ({
  text: {
    width: (props: any) => props.width,
    marginTop: (props: any) => props.marginTop,
  },
  backgroundColor: {
    backgroundColor: (props: any) => props.backgroundColor,
    height: "45px",
    borderRadius: "10px",
    fontFamily: "Avenir Book, sans-serif",
  },
  underline: {
    "&:after": {
      borderBottom: "1.5px solid #1D4E89",
    },
    "&.Mui-disabled": {
      "&:before": {
        borderBottomStyle: "none",
      },
    },
  },
  label: {
    color: (props: any) => props.color,
    top: -10,
    fontFamily: "Avenir Book, sans-serif",
    fontSize: 20,
  },
  icon: {
    color: "gray",
    marginLeft: "10px",
  },
  helperText: {
    fontSize: "13px",
    textAlign: "center",
  },
}));

const Email: FunctionComponent<emailType> = ({
  value = "",
  autoFocus = false,
  helperText = "",
  error = false,
  color = "fff",
  disabled = false,
  backgroundColor = "#fff",
  onChangeHandler,
  marginTop,
  width,
}) => {
  const classes = useStyles({ color, backgroundColor, marginTop, width });
  return (
    <TextField
      id="email"
      name="email"
      label="Email"
      type="email"
      margin="normal"
      placeholder="your@email.com"
      value={value}
      error={error}
      fullWidth
      helperText={helperText}
      disabled={disabled}
      FormHelperTextProps={{
        className: classes.helperText,
      }}
      className={classes.text}
      InputLabelProps={{
        className: classes.label,
      }}
      autoComplete="email"
      autoFocus={autoFocus}
      onChange={onChangeHandler}
      InputProps={{
        className: classes.backgroundColor,
        classes: {
          underline: classes.underline,
        },
        startAdornment: (
          <InputAdornment position="start">
            <EMAIL className={classes.icon} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Email;
